import { createReducer, on } from "@ngrx/store";
import * as featureFlagsActions from "./feature-flags.actions";
import * as _ from "lodash";
import { FeatureFlagModel } from "../../models/feature-flag.model";

export interface FeatureFlagStateModel {
  initialState: boolean,
  success: boolean,
  errorCode: string,
  errorMessage: string;
  featureFlags: FeatureFlagModel[];
}

export const featureFlagsInitialState: FeatureFlagStateModel = {
  initialState: true,
  success: true,
  errorCode: '',
  errorMessage: '',
  featureFlags: []
};

export const featureFlagsReducer = createReducer(
  featureFlagsInitialState,
  on(featureFlagsActions.loadFeatureFlags, (state) => state),
  on(featureFlagsActions.setFeatureFlags, (state, { featureFlags }) => ({
    ..._.cloneDeep(state),
    initialState: false,
    featureFlags
  })),
  on(featureFlagsActions.getFeatureFlagsError, (state, { errorCode, errorMessage }) => ({
    ..._.cloneDeep(state),
    initialState: false,
    success: false,
    errorCode,
    errorMessage
  }))

);
