<div class="container-fluid">
    <div class="row d-flex justify-content-center align-items-center vh-100 reset-box">
        <form class="col-md-3 box-border-password large" [formGroup]="formResetPassword" (ngSubmit)="onSubmit()">
        <div class="started">
                <div class="row d-flex flex-column align-items-center text-center">
                    <div class="col-sm-6">
                        <img class="logo" src="../../assets/images/petszelLogo.png">
                    </div>
                </div>
                <div class="row d-flex flex-column align-items-center text-center">
                    <div class="col-sm-8">
                        <h3 class="font h3">Enter Password</h3>
                    </div>
                </div>
                <div class="col-sm-12">
                    <div class="row">
                        <div class="col-sm-7">
                            <label class="label h6">Password</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 input-group">
                          <input class="form-control border-radius" [type]="fieldTextType ? 'text' : 'password'"
                              formControlName="newPassword" placeholder="password" id="password" required
                              (input)="showRequirements()" />

                          <button
                            type="button"
                            class="toggle-password"
                            (click)="fieldTextType = !fieldTextType"
                          >
                            <i
                              [class]="fieldTextType ? 'bi bi-eye-slash' : 'bi bi-eye'"
                            ></i>
                          </button>
                        </div>
                    </div>

                </div>
            </div>
            <div class="row d-flex justify-content-end">
                <div class="col-sm-12 input-container">
                    <div class="row d-flex flex-nowrap">
                        <div class="col-sm-6 col-5">
                            <label class="label h6">Confirm Password</label>
                        </div>
                        <div class="col-sm-6 col-7 d-flex justify-content-end align-items-end"
                            *ngIf="formResetPassword.hasError('NoPasswordMatch', ['confirmPasswordReset'])">
                            <label class="danger">Passwords do not match</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 input-group">
                          <input class="form-control border-radius" [type]="fieldTextType ? 'text' : 'password'"
                              formControlName="confirmPasswordReset" placeholder="password" id="confirmPassword" />

                          <button
                            type="button"
                            class="toggle-password"
                            (click)="fieldTextType = !fieldTextType"
                          >
                            <i
                              [class]="fieldTextType ? 'bi bi-eye-slash' : 'bi bi-eye'"
                            ></i>
                          </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row confirm-password d-flex justify-content-end">
                <div class="col-sm-7 d-flex flex-column flex-shrink-0">
                    <label class="col">
                        <p class="requirements">Password Requirements:</p>
                        <i class="bi bi-check"
                          [ngClass]="formResetPassword.controls['newPassword'].hasError('required') || formResetPassword.controls['newPassword'].hasError('minlength')  ? 'text-danger' : 'text-success'"></i>
                        Must be at least 10 characters!
                    </label>
                    <label class="col">
                        <i class="bi bi-check"
                          [ngClass]="formResetPassword.controls['newPassword'].hasError('required') || formResetPassword.controls['newPassword'].hasError('hasNumber')  ? 'text-danger' : 'text-success'"></i>
                        Must contain at least 1 number!
                    </label>
                    <label class="col">
                        <i class="bi bi-check"
                          [ngClass]="formResetPassword.controls['newPassword'].hasError('required') || formResetPassword.controls['newPassword'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'"></i>
                        Contain uppercase letters
                    </label>
                    <label class="col">
                        <i class="bi bi-check"
                          [ngClass]="formResetPassword.controls['newPassword'].hasError('required') || formResetPassword.controls['newPassword'].hasError('hasSmallCase') ? 'text-danger' : 'text-success'"></i>
                        Contain lowercase letters
                    </label>
                </div>
                <div class="col-sm-5 d-flex mt-4 justify-content-center">
                    <input class="btn btn-primary" type="submit" value="Submit" [disabled]="formResetPassword.invalid || formResetPassword.hasError('NoPasswordMatch')">
                </div>
            </div>

        </form>
        <div class="row d-flex justify-content-center">
            <div class="col-xl-4 col-md-7 col-11">
              <div *ngIf="showSuccessBanner" class="alert alert-success text-center">
                Password created, please check your email to login!
              </div>
            </div>
          </div>
    </div>
</div>
