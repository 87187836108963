import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { FeatureFlagsService } from "../../service/feature-flags.service";
import {
  GET_FEATURE_FLAGS_ERROR,
  LOAD_FEATURE_FLAGS,
  SET_FEATURE_FLAGS
} from "./feature-flags.actions";
import { exhaustMap, map, of, retry } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class FeatureFlagsEffects {
  constructor(private actions: Actions,
              private featureFlagsService: FeatureFlagsService) {
  }

  loadFeatureFlags$ = createEffect(() => {
    return this.actions.pipe(
      ofType(LOAD_FEATURE_FLAGS),
      exhaustMap(() => this.featureFlagsService.getFeatureFlags()
        .pipe(
          map(featureFlags => ({
            type: SET_FEATURE_FLAGS,
            featureFlags
          })),
          retry(3),
          catchError(error => of({
            type: GET_FEATURE_FLAGS_ERROR,
            errorCode: error.status,
            errorMessage: error.message
          }))
        )
      )
    );
  })
}
