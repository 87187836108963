import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { PetszelSharedModule, SharedLibraryService, sharedLibraryConfigProvider } from 'projects/petszel-shared/src/public-api';
import { appConfig } from '../owner.config';
import { PetArticlePageComponent } from './pages/pet-article-page/pet-article-page.component';
import { ContentfulService } from './service/contentful.service';
import { AuthGuardService } from './service/authentication-guard.service';
import { AuthInterceptor } from './service/auth-interceptor';
import { EmailResetComponent } from './pages/email-reset/email-reset.component';
import { TermsAndConditionsModalComponent } from './components/terms-and-conditions-modal/terms-and-conditions-modal.component';
import { StoreModule } from "@ngrx/store";
import { petsReducer } from "./shared-store/pet/pet.reducer";
import { EffectsModule } from "@ngrx/effects";
import { PetEffects } from "./shared-store/pet/pet.effects";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { sheltersReducer } from "./shared-store/shelter/shelter.reducer";
import { ShelterEffects } from "./shared-store/shelter/shelter.effects";
import { ownerReducer } from "./shared-store/owner/owner.reducer";
import { OwnerEffects } from "./shared-store/owner/owner.effects";
import { logoReducer } from "./shared-store/logo/logo.reducer";
import { loadStateReducer } from "./shared-store/app-state.reducer";
import { selectedVariablesReducer } from "./shared-store/selected-variables/selected-variables.reducer";
import { featureFlagsReducer } from "./shared-store/feature-flags/feature-flags.reducer";
import { FeatureFlagsEffects } from "./shared-store/feature-flags/feature-flags.effects";


@NgModule({
  declarations: [
    AppComponent,
    PetArticlePageComponent,
    EmailResetComponent,
    TermsAndConditionsModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    RouterModule,
    CommonModule,
    PetszelSharedModule,
    LoggerModule.forRoot({
      level: NgxLoggerLevel.DEBUG, // set log level
      serverLogLevel: NgxLoggerLevel.ERROR, // set server log level
      disableConsoleLogging: false, //enable/disable console logging
    }),
    StoreModule.forRoot({
      pets: petsReducer,
      shelters: sheltersReducer,
      owner: ownerReducer,
      latestAdoptionLogo: logoReducer,
      loadState: loadStateReducer,
      selectedVariables: selectedVariablesReducer,
      featureFlags: featureFlagsReducer
    }),
    EffectsModule.forRoot([
      PetEffects,
      ShelterEffects,
      OwnerEffects,
      FeatureFlagsEffects
    ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25
    })
  ],
  providers: [
    {
      provide: 'appConfig',
      useValue: appConfig,
    },
    ContentfulService, // Add ContentfulService to providers
    AuthGuardService, // Add AuthGuardService to providers
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    sharedLibraryConfigProvider,
    SharedLibraryService,

    // ... other providers
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
