import { createAction, props } from "@ngrx/store";
import { FeatureFlagModel } from "../../models/feature-flag.model";

export const LOAD_FEATURE_FLAGS = 'Load Feature Flags';
export const SET_FEATURE_FLAGS = 'Set Feature Flags';
export const GET_FEATURE_FLAGS_ERROR = 'Get Feature FlagS Error';

export const loadFeatureFlags = createAction(LOAD_FEATURE_FLAGS);
export const setFeatureFlags =
  createAction(SET_FEATURE_FLAGS, props<{ featureFlags: FeatureFlagModel[] }>());
export const getFeatureFlagsError =
  createAction(GET_FEATURE_FLAGS_ERROR, props<{ errorCode: string, errorMessage: string }>());
