import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { appConfig } from "../../owner.config";
import { FeatureFlagModel } from "../models/feature-flag.model";

@Injectable({
  providedIn: 'root'
})
export class FeatureFlagsService {
  private token = localStorage.getItem('token');
  private headers: HttpHeaders;

  constructor(private http: HttpClient) {
    if (this.token) {
      this.headers = new HttpHeaders().set('Authorization', `Bearer ${this.token}`);
    } else {
      this.headers = new HttpHeaders();
    }
  }

  getFeatureFlags(): Observable<FeatureFlagModel[]> {
    const url = `${appConfig.apiUrl}FeatureFlags/TeleAdvice`;
    return this.http.get<FeatureFlagModel[]>(url, { headers: this.headers });
  }
}
