import {
  Component,
  Input,
  OnInit,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
} from '@angular/core';
import { LogoutService } from '../services/logout.service';
import { AuthGuardService } from 'projects/petszel-owner/src/app/service/authentication-guard.service';
import { AuthGuard } from 'projects/petszel-shelter/src/app/service/authentication-guard.service';
import { AuthenticationGuardService } from 'projects/petszel-staff/src/app/service/authentication-guard.service';
import { NGXLogger } from 'ngx-logger';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import * as bootstrap from 'bootstrap';

@Component({
  selector: 'lib-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
})
export class NavigationComponent implements OnInit {
  @Input() navItems!: any[];
  @Input() item: any;
  @Input() navItems2!: any[];
  @Input() navItems3!: any[];
  @Input() shelterLogoSrc!: string;
  @Input() size!: string;
  @Input() brandingDays!: number;
  @Input() daysSinceAdoption!: number;
  @Input() isApplication1!: boolean;
  @ViewChild('navbarNav', { static: false }) menuElement!: ElementRef;
  @Output() actionTriggered = new EventEmitter<string>();
  isMenuOpen = false;
  isLoggedIn: boolean = false;
  isOffcanvasOpen = false;
  isNavbarCollapsed = true;
  @Input() logo: any;

  constructor(
    private logoutService: LogoutService,
    private authGuard: AuthGuard,
    private authGuardService: AuthGuardService,
    private authenticationGuardService: AuthenticationGuardService,
    private logger: NGXLogger,
    private router: Router
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.isMenuOpen = false; // Close the menu on any navigation end
      });
  }

  ngOnInit() {
    this.isLoggedIn = this.checkIsLoggedIn();
    this.logoutService.logoutAfterDelay(4000 * 60 * 60); // Logout after 4 hours
  }

  performAction(action: string) {
    this.actionTriggered.emit(action);

    switch (action) {
      case 'openOffcanvas':
        this.openOffcanvas();
        break;
    }
  }

  performItemClick(item: any, event: MouseEvent) {
    if (item.action) {
      this.performActionSm(item.action);
    } else {
      this.isMenuOpen = false;
    }
    event.preventDefault();
  }

  performActionSm(action: string) {
    this.toggleMenu();

    switch (action) {
      case 'openOffcanvas':
        this.openOffcanvasSm();
        break;
    }
  }

  openOffcanvasSm() {
    const offcanvasElement = document.getElementById(
      'emergencyOffcanvasBottom'
    );

    if (offcanvasElement instanceof Element) {
      const bsOffcanvas = new bootstrap.Offcanvas(offcanvasElement);
      bsOffcanvas.show();
    } else {
      console.error('Offcanvas element not found');
    }
  }

  openOffcanvas() {
    const offcanvasElement = document.getElementById('emergencyOffcanvasRight');
    if (offcanvasElement instanceof Element) {
      const bsOffcanvas = new bootstrap.Offcanvas(offcanvasElement);
      bsOffcanvas.show();
    } else {
      console.error('Offcanvas element not found');
    }
  }

  logoutBtn() {
    this.logoutService.logoutBtn();
  }

  checkIsLoggedIn(): boolean {
    return (
      this.authGuardService.isLoggedIn() ||
      this.authGuard.isLoggedIn() ||
      this.authenticationGuardService.isLoggedIn()
    );
  }

  onLinkClick(queryParams: any) {
    if (queryParams) {
      this.toggleNavbar();
      this.isMenuOpen = false;
    }
  }

  toggleMenu() {
    if (this.isMenuOpen) {
      this.closeHamburger();
    }
    if (this.menuElement && this.menuElement.nativeElement) {
      this.isMenuOpen = !this.isMenuOpen;
      if (!this.isMenuOpen) {
        this.menuElement.nativeElement.classList.add('show');
      } else {
        this.menuElement.nativeElement.classList.remove('show');
      }
    } else {
      console.warn('Menu element or its native element is not available');
    }
  }

  closeHamburger() {
    this.isMenuOpen = false;
  }

  closeOffcanvas() {
    this.isOffcanvasOpen = false;
  }

  toggleNavbar() {
    this.isNavbarCollapsed = !this.isNavbarCollapsed;

    if (this.isOffcanvasOpen) {
      this.closeOffcanvas();
    }
    this.isMenuOpen = !this.isMenuOpen;
    const navbarToggler = document.getElementById('navbarToggler');
    if (navbarToggler) {
      if (this.isMenuOpen) {
        navbarToggler.classList.remove('collapsed');
      } else {
        navbarToggler.classList.add('collapsed');
      }
    }

    if (this.menuElement && this.menuElement.nativeElement) {
      if (this.isMenuOpen) {
        this.menuElement.nativeElement.classList.add('show');
      } else {
        setTimeout(() => {
          this.menuElement.nativeElement.classList.remove('show');
        }, 0);
      }
    }
  }

  toggleExpansion(item: any): void {
    if (item.subItems) {
      item.isExpanded = !item.isExpanded;
      this.logger.log(item.label + ' isExpanded:', item.isExpanded);
    }
  }

  toggleSubMenu(item: any, event: MouseEvent): void {
    item.isExpanded = !item.isExpanded;
    event.preventDefault();
    event.stopPropagation();
  }
}
